import { useFirebaseAuth } from '@flock/utils'

const Logout = () => {
  const { useValidateAuthLoadedAndLogout } = useFirebaseAuth()
  useValidateAuthLoadedAndLogout()

  return null
}

export default Logout
